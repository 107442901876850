<template>
  <div id="termsOfService" style="background-color: #eff3f5;height: 100%;width: 100%;overflow: scroll" align="center" overflow="scroll">
    <a-card style="background-color: white;width: 1100px;margin-top: 50px;padding-left: 20px;padding-right: 20px">
      <div style="margin-top: 20px">
        <h3>
          <p style="font-size: 26px">{{ themeData.saasMerNameZh }}服务条款</p>
        </h3>
      </div>
      <div align="left" style="margin-top: 50px">
        <div>
          <p>
            这些使用条款，指导您使用{{ themeData.saasMerNameZh }}提供的服务。请仔细阅读这些使用条款，因为它们构成了您和我们之间的法律关系。<br>
            这些使用条款包括本页面和以下内容：<br>
            1.     本协议条款<br>
            2.     {{ themeData.saasMerNameZh }}是谁，如何联系我们<br>
            3.     本协议条款的变更<br>
            4.     身份检查和信息要求<br>
            5.     关于我们的服务<br>
            6.     入门指引<br>
            7.     授权用户<br>
            8.     收款服务<br>
            9.     外汇兑换服务<br>
            10.     付款服务<br>
            11.     撤消和拒绝<br>
            12.     安全和保障<br>
            13.     费用和收费<br>
            14.     知识产权<br>
            15.     数据的保密、隐私和使用<br>
            16.     披露信息和使用个人数据进行直接营销<br>
            17.     责任限制<br>
            18.     赔偿<br>
            19.     协议终止和中止<br>
            20.     第三方服务供应商<br>
            21.     声明与保证<br>
            22.     市场动荡、错误和不可抗力<br>
            23.     一般条款<br>
            24.     定义和解释<br>
            除了使用条款外，以下附加文件也适用于您使用支付平台和服务，并应与这些使用条款一起阅读：<br>
            <a @click="clauseSupplement">-服务条款补充协议</a><br>
            <a @click="usePolicy">-可接受的使用政策</a><br>
            本使用条款和附加文件可不时补充或修改，我们将根据适用法律和本使用条款提前通知您。您可以在我们的网站上看到这些文件的最新版本。我们建议您通过打印或下载的方式保存本使用条款的副本，以供今后参考。<br>
            通过在我们的网站注册使用我们的任何服务，您同意受这些使用条款的约束（可能不时补充或修改），这些条款可通过我们的网站在线提供给您。通过单击按钮“创建帐户”或类似按钮，注册、访问或使用服务（统称为“默示同意”），您将与{{ themeData.saasMerNameZh }}订立具有法律约束力的协议，并同意接受这些使用条款。如果您不接受这些使用条款（或对这些使用条款的任何补充或修改），请不要使用我们的任何服务。<br>
            如果在给予您的默示同意后，您和{{ themeData.saasMerNameZh }}同意执行这些使用条款的纸质版本，这些使用条款的纸质版本也将是有效的，并在您第一次给予您的默示同意之日起追溯生效。若线上版本和纸质版本之间存在不一致，以线上版本为准。<br>
          </p>
        </div>
        <div align="left">
          <p class="navigationTitle">1.本协议条款</p>
        </div>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle">1.1 各方</span><br>
            在使用{{ themeData.saasMerNameZh }}平台和服务时，您（"客户/您/您的"）同意这些适用于您和{{ themeData.saasMerNameZh }} Limited
            （"{{ themeData.saasMerNameZh }}/我们/我们的"）之间的本协议条款。请仔细阅读这些条款，如有任何不清楚的地方，请联系我们。
          </p>
          <p class="detail">
            附加文件 以下附加文件（不时更新）也适用于您对{{ themeData.saasMerNameZh }}平台和服务的使用，并应与本协议条款一起阅读。
          </p>

          <p>(a)可接受的使用政策</p>
          <p> (b)隐私政策</p>
          <p>(c)产品协议 / {{ themeData.saasMerNameZh }}跨境支付主协议</p>

          <p>
            当我们向您提供其他服务时，您应参考这些其他服务的条款和条件。
          </p>
          <p class="detail">
            <span class="detailTitle">1.2 获取这些条款。</span>
            <br>您可以随时在我们的网站上看到这些文件的最新版本，包括本协议条款，网址是{{ themeData.website }}。您也可以下载这些文件的副本，并保存起来以备将来参考，或随时向我们索取有效副本。
          </p>
          <p class="detail">
            <span class="detailTitle">1.3 联系我们。<br></span>如果我们需要以您可以保存的形式向您发送信息，我们会向您发送电子邮件，或在我们的网站上提供信息，或通过您可以下载的{{ themeData.saasMerNameZh }}平台。请保留我们发送给您的所有通信的副本。
          </p>
        </div>
        <p class="navigationTitle">
          2.{{ themeData.saasMerNameZh }}是谁，如何联系我们？
        </p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle">2.1我们公司的信息。<br></span>本服务由根据香港法律注册成立的{{ themeData.saasMerNameZh }} Limited提供。{{ themeData.saasMerNameZh }} Limited 已获香港海关根据《打击洗钱及恐怖分子资金筹集条例》授权提供汇款服务和货币兑换服务。我们有权直接或通过我们的一个或多个集团公司或服务供应商提供服务。
          </p>
          <p class="detail">
            <span class="detailTitle"> 2.2如何与我们联系。<br></span>您可以通过以下方式联系我们：<br>

            (a)发送电子邮件至：{{ themeData.email }}；<br>
            (b)通过我们网站上的联系链接给我们发信息，{{ themeData.website }}。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 2.3我们将如何与您联系。<br></span>我们将使用您在注册时提供的联系方式与您联系——请及时更新这些联系方式。使用我们的服务，即表示您同意接收我们的电子通讯。如果我们有合理理由担心您的{{ themeData.saasMerNameZh }}账户的安全，或怀疑或实际使用您的{{ themeData.saasMerNameZh }}账户或其相关账户的欺诈行为，我们将通过电话、电子邮件或这两种方式与您联系（除非与您联系是非法的或损害我们合理的安全措
            施）。在合理认为与您联系是非法的或是损害我们的合理的安全措施的情况下，我们会在做出相应行动前至少一周以国际快递/邮政的方式向您的登记地址寄送通知。
          </p>
        </div>
        <p class="navigationTitle">
          3.本协议条款的变更
        </p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle"> 3.1一般性变更。<br></span>我们有权通过提前一(1)个月向您发出书面通知来更改这些条款。如果您在通知期内未向我们发出书面通知终止本协议条款，则视为您已接受拟议的变更。
          </p>
          <p class="detail">
            <span class="detailTitle"> 3.2紧急变更。<br></span>我们也可能会在不事先通知的情况下立即做出一些更改，如果它们：<br>
            (a)适用法律所要求的；<br>
            (b)这些条款与我们的服务中增加的新服务或额外功能有关，不影响与现有服务有关的任何条款。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 3.3如果您不同意变更。<br></span>如果您不同意任何变更，您应该停止使用我们的相应服务。如果您在我们宣布变更后继续使用我们的相应服务，则视为您已接受该变更。
          </p>
          <p class="detail">
            <span class="detailTitle"> 3.4修改。<br></span>我们可能会在任何时候修改{{ themeData.saasMerNameZh }}平台的配置、外观或功能，以方便{{ themeData.saasMerNameZh }}平台的持续和正常运作或遵守适用法律。如果修改会对您使用{{ themeData.saasMerNameZh }}平台的能力产生重大影响， {{ themeData.saasMerNameZh }}将在合理可行的范围内至少提前一周向您发出关于该变更的通知。
          </p>
          <p class="detail">
            <span class="detailTitle"> 3.5更新。<br></span>我们可能会不时发布{{ themeData.saasMerNameZh }}平台，包括{{ themeData.saasMerNameZh }} APIs的更新。有些更新可能需要您采取措施来实施。在某些情况下（例如，如果存在安全风险），您将无法使用{{ themeData.saasMerNameZh }}平台，直到您实施更新。如果您在我们发布更新后6个月内仍未实施更新，我们有权提前两个月以书面形式通知您，停止向您提供部分服务或终止本协议条款。
          </p>
        </div>
        <p class="navigationTitle">
          4.身份检查和信息要求
        </p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle"> 4.1身份检查。<br></span>根据适用法律并在合法范围内，在我们向您提供服务之前和期间，我们将不时对 您、您的董事、合伙人、最终实际所有人和雇员（如相关）以及您交易中涉及的任何各方进行客户尽职调查。
          </p>
          <p class="detail">
            <span class="detailTitle">4.2信息请求。<br></span>您将在任何时候及时提供我们所需的任何额外信息，除有合理原因外最迟在我们提出要求后5个工作日内提供。例如，我们可能要求提供信息以确认某些交易的真实性。</p>
          <p class="detail">
            <span class="detailTitle">4.3第三方验证。<br></span>您同意我们可以直接或通过第三方进行任何我们认为必要的查询，以核实您向我们提供的信息，包括检查商业数据库或信用报告。我们可能会根据在合法范围内保留此类客户尽职调查的记录。
          </p>
          <p class="detail">
            <span class="detailTitle">4.4信用评估。<br></span>您同意我们提供服务以及您使用服务的能力受制于：<br>

            (a)顺利完成初步和任何后续的信用评估；<br>
            (b)在本协议条款的有效期内，您的信用状况没有重大恶化。<br>
          </p>
        </div>
        <p class="navigationTitle">
          5.关于我们的服务
        </p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle"> 5.1 {{ themeData.saasMerNameZh }}账户和平台。<br></span>一旦您成功向我们注册，我们将为您建立一个{{ themeData.saasMerNameZh }}账户。除非我们同意，否则您只能拥有一个{{ themeData.saasMerNameZh }}账户。如果我们发现您有多于一个{{ themeData.saasMerNameZh }}账户，并且我们合理地相信您建立这些{{ themeData.saasMerNameZh }}账户是为了绕过服务中的任何限制，我们可决定合并或关闭任何重复的资料，或终止对您的服务，但在终止前我们会给你发出书面通知并邀请您阐释您的原因。您可以使用您的{{ themeData.saasMerNameZh }}账户登录并进入{{ themeData.saasMerNameZh }}平台。{{ themeData.saasMerNameZh }}平台将确保为您提供一个用来访问和使用我们的各种服务的易于使用的用户界面。
          </p>
          <p class="detail">
            <span class="detailTitle">5.2服务概述。<br></span>我们根据这本协议条款提供的服务包括：<br>
            (a)收款服务——{{ themeData.saasMerNameZh }}为您以支持货币收取资金。<br>
            (b)付款服务——您可以向收款人发送付款；<br>
            (c)外汇兑换服务——您可以将资金从一种支持货币兑换成另一种支持货币。<br>

            每项服务都是由{{ themeData.saasMerNameZh }}提供的独立服务。<br>

            作为收款、付款或外汇兑换服务的条件，{{ themeData.saasMerNameZh }}将自行决定已经收到的文件或其他信息在形式和内容上是否充足。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">5.3收款服务。<br></span>就收款服务而言，我们将向您提供支持货币的当地银行账户凭证，您可向您的付款人提供该凭证，以便向您汇款。所收取的资金将以每一种支持货币记录在我们为您设立的全球账户中。您可以在任何时候向{{ themeData.saasMerNameZh }}发出相关提款指令，将资金提取到您自己的同名账户。为您收取的资金在全球账户中的停留时间不得超过第8.4条规定的最长期限。
          </p>
          <p class="detail">
            <span class="detailTitle">5.4付款服务和外汇兑换。<br></span>{{ themeData.saasMerNameZh }}允许客户向收款人进行单笔或多笔付款。在付款服务和/或外汇兑换服务方面，{{ themeData.saasMerNameZh }}将设立一个备付金银行账户，您可以向该账户汇款，以支付您的付款和/或外汇兑换指令。{{ themeData.saasMerNameZh }}将根据您在向备付金银行账户汇款时给我们的具体指令，将这些资金以支持货币汇给您指定的收款人，和/或将这些资金从源货币转换为买入货币。
          </p>
          <p class="detail">
            <span class="detailTitle">5.5交易记录。<br></span>您的交易记录将记录在{{ themeData.saasMerNameZh }}账户中，该资料将显示通过全球账户收取的资金(作为收款服务的一部分)以及支付到备付金银行账户的资金(作为付款服务和您的外汇兑换的一部分)。
          </p>
          <p class="detail">
            <span class="detailTitle">5.6存款保障计划。<br></span>香港存款保障计划("存保计划")不适用于与服务有关的资金发送、交换或接收。您承认并同意，我们不是银行，也不向您提供银行账户，因此我们的服务不受存保计划的保障。
          </p>
          <p class="detail">
            <span class="detailTitle">5.7保证您的资金安全。<br></span>为了保证您的资金安全，{{ themeData.saasMerNameZh }}已经采取了商业上合理的管理和技术措施，以保护和保障为您收取或从您那里收到的与服务有关的资金。{{ themeData.saasMerNameZh }}将把该等资金存放在指定的银行账户中，与{{ themeData.saasMerNameZh }}的公司资金分开，并且不会将该等资金用于自身的运营开支。
          </p>
          <p class="detail">
            <span class="detailTitle"> 5.8限制。<br></span>我们可能会根据某些监管要求、您的居住国、身份验证检查或业务要求，对服务的全部或任何部分的使用进行临时或永久限制，所有这些都已在可接受使用政策或任何附加条款中规定。这些限制将在实施时告知您，如果由于紧急或不可预见的情况而无法实施，则将在实施限制后立即通知您。
          </p>
          <p class="detail">
            <span class="detailTitle"> 5.9您与我们的关系。<br></span>根据本协议条款，我们是您的服务提供商。
          </p>
          <p class="detail">
            <span class="detailTitle">5.10服务保证金。<br></span>您须就任何{{ themeData.saasMerNameZh }}所提供的服务，例如付款服务和/或外汇兑换服务，按我们不时要求的金额向我们支付合理保证金。为免生疑问，本公司有权要求您在该等服务的最终结算前随时支付额外的金额以增加保证金的金额，以确保在结算您向我们提供的保证金期间，扣除您可能产生的任何适用费用(如银行间费用、{{ themeData.saasMerNameZh }}的代理银行费用及/或其他处理费用)，仍有足够的金额继续提供服务。
          </p>
          <p class="detail">
            <span class="detailTitle"> 5.11选定服务。<br></span>
            (a)客户在订购期内订购服务。<br>
            (b){{ themeData.saasMerNameZh }}应根据本协议及本服务附表提供上述服务。每项订购的服务的订购期（如适用）应自动续订6个月，除非客户在订购期结束前30天向{{ themeData.saasMerNameZh }}发出书面通知，明确说明客户不愿意续订。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 5.12工作时间<br></span>
            {{ themeData.saasMerNameZh }}的工作时间：北京时间每个工作日上午9:30至12:00，下午13:00至16:00，但不包括中国大陆、香港和/或任何其他由{{ themeData.saasMerNameZh }}或其第三方供应商经营的司法管辖区的节假日。<br>
          </p>
        </div>
        <p class="navigationTitle">
          6.入门指引
        </p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle">6.1创建您的{{ themeData.saasMerNameZh }}账户。<br></span>为了使用我们的服务，您必须根据{{ themeData.saasMerNameZh }}平台的提示提供必要的信息，以注册和创建您的{{ themeData.saasMerNameZh }}账户。如果{{ themeData.saasMerNameZh }}要求，您必须提供您的银行账户信 息，以便与您的{{ themeData.saasMerNameZh }}账户绑定。
          </p>
          <p class="detail">
            <span class="detailTitle"> 6.2开设全球账户。<br></span>当我们建立了您的{{ themeData.saasMerNameZh }}账户后，您可以登入{{ themeData.saasMerNameZh }}的平台，并提供必要的信息，包括我们可能不时要求的任何额外数据，以提交开设全球账户的要求。您还需要同意相应的附加条款。前述文件或者条款我们均会在您申请开立全球账户时或之前告知您相关要求。
          </p>
          <p class="detail">
            <span class="detailTitle"> 6.3设置{{ themeData.saasMerNameZh }} API。<br></span>您可以通过{{ themeData.saasMerNameZh }}的API和MSP访问{{ themeData.saasMerNameZh }}平台。但要通过{{ themeData.saasMerNameZh }} API使用我们的服务，您必须先获得我们的批准。如果您获得我们的批准，您将需要遵守我们的API文件以及有关整合和使用{{ themeData.saasMerNameZh }} API的任何合理指示。
          </p>
          <p class="detail">
            <span class="detailTitle"> 6.4信息验证和测试。<br></span>只有当我们对您提供的信息以及我们不时合理要求的任何后续信息感到满意，并且我们能够核实这些信息并使我们感到满意时，我们才会创建您的{{ themeData.saasMerNameZh }}账户并允许您使用我们的服务。在我们接受您为客户之前，我们可能会为您建立一个全球账户，并提供您进入{{ themeData.saasMerNameZh }}平台的方式（不论是通过{{ themeData.saasMerNameZh }} API或MSP）进行测试。此外，我们可能会要求您向全球账户传输少量资金以进行测试或验证。为免生疑问，除非且直至我们确认您已被接纳为我们的客户，否则您不会被视为我们的客户，您亦不能使用我们的任何服务，而如果我们不接纳您为客户，您为测试或验证目的而向我们提供的任何款项将全数退还给您。
          </p>
        </div>
        <p class="navigationTitle">7.授权用户<br></p>
        <div style="margin-left: 20px">
          <p class="detail">
            <span class="detailTitle"> 7.1授权用户。<br></span>您可委任授权用户代表您根据本协议条款订立及确认外汇兑换及付款。您必须为每个授权用户建立一个用户资料，并立即向我们提供您任何建议的授权用户的下列详细资料：客户名称、授权用户名称、客户地址和账单地址、授权用户的电话和电子邮件地址、董事会决议或授权书，以及我们可能合理要求的授权用户的任何其他联系或身份信息。您将确保您的授权用户遵守本协议条款，就您在本协议条款下的义务及责任而言，对“您”的提述（如文义要求）应理解为包括您的授权用户。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 7.2指令。<br></span>我们将通过MSP或{{ themeData.saasMerNameZh }} API由{{ themeData.saasMerNameZh }}平台从您的授权用户处接收指令，或根据与我们事先商定的安排，通过{{ themeData.saasMerNameZh }}的代表人/员工接收指令。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">7.3确认。<br></span>您同意：<br>

            (a)您的授权用户有权代表您向我们提供服务的指令。<br>
            (b)我们将依赖授权用户的授权，而您将受您的授权用户的行为约束，直到您向我们提供书面通知撤回或以其他方式更改授权用户的授权。<br>
            (c)您有责任确保适当的人获得必要的授权作为您的授权用户；<br>
            (d)如果我们担心未经授权或欺诈性访问，我们可能会拒绝接入您的授权用户；<br>
            (e)如有任何侵犯或未经授权访问{{ themeData.saasMerNameZh }}平台（包括MSP或{{ themeData.saasMerNameZh }} APIs）的行为，您应立即向我们报告。<br>
          </p>
        </div>
        <p class="navigationTitle">8.收款服务<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle"> 8.1通过{{ themeData.saasMerNameZh }}收取资金。<br></span>如果我们已经为您批准了一种支持货币，您可以根据本协议条款通过
            {{ themeData.saasMerNameZh }}收取该支持货币的资金。每次收款都是您和{{ themeData.saasMerNameZh }}之间的一个单独合同。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 8.2您提取收款的权利。<br></span>{{ themeData.saasMerNameZh }}将以我们的名义在一个或多个相关银行账户中收取并持有所收取的资金，用于提供收款服务的指定用途。任何相关银行账户中的资金将与{{ themeData.saasMerNameZh }}提供的服务的其他用户的资金集中在一起。您有权将代您收取的资金支付给您，作为向您提供的收款服务的一部分。当您要求提款时，{{ themeData.saasMerNameZh }}必须根据本协议条款的规定向您支付这些资金。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 8.3记录资金的接收情况。<br></span>我们只有在收到资金时才会更新您的全球账户。在我们实际收到资金之 前，我们不对任何转入的资金负责，也不会将其记录在全球账户中。从您的付款人向{{ themeData.saasMerNameZh }}指定的相关账户转账是由第三方提供的服务，而不是我们服务的一部分。我们无法控制该等资金转移到清算和结算所需的时间。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 8.4从全球账户中提取资金。<br></span>您必须立即指示我们提取全球账户中的资金。如果您没有在合理的时间<br>
            (在任何情况下都不能超过90个工作日或由我们决定)内向我们发出提款指示，我们将自行决定。<br>
            (a)将资金返还给汇款人；<br>
            (b)按照通知我们的方式将资金汇入您绑定的银行账户；<br>
            (c)以适用法律允许的任何其他方式将资金汇给您。在作出前述决定后，我们会给您发出书面通知。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 8.5资金来源。<br></span>除非我们另有书面同意，您必须确保为您收取的所有资金均来自合法的商业来源（例如，销售商品或服务的收益），并符合我们的可接受使用政策。您同意在任何时候都不将您自己的任何资金转入（例如充值）全球账户。您同意向我们提供资金来源的证据。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">8.6限制将资金存入全球账户。<br></span>全球账户只用于向您提供收款服务。您不得将自己的资金转入全球账户。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">8.7作为有限代理。<br></span>您向{{ themeData.saasMerNameZh }}陈述并保证，您通过收款服务收到的每笔款项均为您向您的客户<br>
            （各自称为"付款人"）提供真正的商品和/或服务而支付的款项。就收款服务而言，您特此指定{{ themeData.saasMerNameZh }}，而{{ themeData.saasMerNameZh }}特此同意作为您的有限代理，仅代表您从您的付款人处收取付款。您同意，一旦{{ themeData.saasMerNameZh }}收到付款人的付款，则<br>
            (a) 您应被视为已收到该付款人的付款，<br>
            (b) 该付款人对您所承担的与该付款有关的义务应被完全履行，<br>
            (c) 在{{ themeData.saasMerNameZh }}将该付款实际支付并汇给您之后，您有义务根据您和该付款人的协议向该付款人交付适用的货物和/或服务。{{ themeData.saasMerNameZh }} 将根据本协议条款向您汇款。您应向您的付款人表明，{{ themeData.saasMerNameZh }}是作为您的代理人代表您接收付款。提供给付款人的任何收据对您具有约束力，并应满足所有适用的监管要求。本条第8.7条规定了{{ themeData.saasMerNameZh }}作为您在收款服务项下的代理收取款项的全部职责，{{ themeData.saasMerNameZh }}承诺以该身份行事。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">8.8申请开立全球账户。 <br></span>客户可以登录{{ themeData.saasMerNameZh }}平台提交开立全球账户的申请，并根据提示在平台上提供所需信息，包括但不限于以下信息：<br>
            (a)所申请的全球账户的管辖范围、市场和货币；<br>
            (b)在创建全球账户之前，{{ themeData.saasMerNameZh }}通过平台要求的或{{ themeData.saasMerNameZh }}不时要求的任何其他信息。只有当{{ themeData.saasMerNameZh }}确信客户已经提供了足够的细节和信息来为客户开立全球账户，并且{{ themeData.saasMerNameZh }}能够核实这些信息时，{{ themeData.saasMerNameZh }}才会为客户创建全球账户。{{ themeData.saasMerNameZh }}保留拒绝为客户开立全球账户的权利。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">8.9如何将资金转入全球账户<br></span>
            (a)客户需要登录{{ themeData.saasMerNameZh }}平台，并获取相关支持货币的全球账户的虚拟/附属账户信息。在{{ themeData.saasMerNameZh }}实际收到资金之前，{{ themeData.saasMerNameZh }}不对转账资金负责。<br>
            (b)客户或任何付款人可以通过银行转账，使用{{ themeData.saasMerNameZh }}在{{ themeData.saasMerNameZh }}平台上显示的虚拟/附属账户信息或{{ themeData.saasMerNameZh }}通过{{ themeData.saasMerNameZh }}平台不时允许的任何其他方式将资金转入全球账户。<br>
            (c)向全球账户转账不属于{{ themeData.saasMerNameZh }}提供的服务，而是由第三方向客户提供的服务。提供给客户的全球账户仅用于在合法合规的商品交易或提供服务的背景下从电商平台或交易方收取资金，因此提供给客户的全球账户只能用于存款。在此情况下，除非经{{ themeData.saasMerNameZh }}同意，否则客户不得使用该账户进行任何直接扣款服务，包括但不限于定期自动扣取水电费或租金、退还销售收入、定期支付服务费和/或等。当客户的全球账户发生任何直接扣款交易时，{{ themeData.saasMerNameZh }}有权拒绝或终止该交易但需书面通知客户。<br>
          </p>
        </div>
        <p class="navigationTitle">9.外汇兑换服务<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">9.1您对外汇兑换的指令。<br></span>您可以在{{ themeData.saasMerNameZh }}平台按照以下步骤提供必要的信息，指令我们进行外汇兑换：<br>

            (a)客户应通过{{ themeData.saasMerNameZh }}平台提交与外汇交易有关的客户请求，其中包括但不限于以下信息：(i) 买入货币和卖出货币的货币配对； (ii) 买入货币和卖出货币的金额； (iii) 结算日期（如适用）；以及 (iv) {{ themeData.saasMerNameZh }}在发出订单确认之前通过{{ themeData.saasMerNameZh }}平台不时要求的任何其他信息。<br>
            (b)在收到客户请求后，{{ themeData.saasMerNameZh }}将随后发送一份订单确认书，确认客户请求的细节。该订单确认应包含最终确定的外汇汇率，该汇率可能与最初向客户提供的外汇汇率不同。{{ themeData.saasMerNameZh }}应在合理可行的情况下，努力使{{ themeData.saasMerNameZh }}代理银行或流动性提供者提供的最终外汇汇率密切反映出首次向客户报价的外汇汇率。<br>
            (c)如果订单确认上列明的日期不是工作日，那么该日期将调整为下一个最近的工作日的第一天。<br>
            (d)当{{ themeData.saasMerNameZh }}认为客户已经提供了足够的细节和信息以完成订单确认时，外汇交易才成立并具有约束力。<br>
            (e){{ themeData.saasMerNameZh }}可以通过商户服务平台、外汇API或其他任何{{ themeData.saasMerNameZh }}认为合适的方式发送订单确认。<br>
            (f)向客户发出订单确认意味着客户已经预订了该交易。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">9.2外汇兑换的确认。<br></span>当我们确信您的指令已完成，我们将向您发出有关外汇兑换的确认。如果您在结算截止日没有足够的资金、您超过了任何适用的限额、您要求的外汇兑换被我们视为杠杆式外汇交易，或根据第19条，我们可能会拒绝您的指令。一旦确认发出，您不能取消外汇兑换，并被视为最终确认。然后，您必须根据本协议条款的规定，以相关源货币与我们进行全额外汇兑换结算。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">9.3外汇汇率。<br></span>您可以通过{{ themeData.saasMerNameZh }}平台获取外汇兑换的指示性外汇汇率。当您通过{{ themeData.saasMerNameZh }}平台向我们发出指示时，我们将向您提供指示性外汇汇率，而最终的外汇汇率将在我们发出确认书时予以确认。我们将在合理可行的情况下确保确认的外汇汇率反映向您提供的汇率，但您承认这并非总是可能的。由于在您发出指示至我们发出确认书期间，汇率可能会有所改变，因此用于进行外汇兑换的最终外汇汇率可能会有所不同。您同意，汇率的变化将立即生效，恕不另行通知。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">9.4外汇兑换的结算规定。<br></span>您必须在结算截止日或之前以相关源货币向备付金银行账户支付足够的资金，以支付您希望进行的任何外汇兑换的全部金额。您可透过直接银行转账或其他可接受的方式为外汇兑换提供资金。在不影响我们在本协议条款下的任何权利的前提下，您有义务在我们提出要求后两(2)个工作日内，或按照约定的结算日期，将该等款项支付到我们指定的账户，您明确承认，如果您未能就任何外汇兑换交易支付我们要求的任何保证金，或没有向备付金银行账户支付足够的资金以支付全部款项，我们可以取消外汇兑换交易，而无需事先通知您，并将保证金用于支付任何应付给我们的款项。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">9.5外汇兑换的执行时间。<br></span>如果您要求我们进行外汇兑换，我们将在合理可行的情况下尽快进行，在任何情况下，即不超过我们接受您的指令后24小时内。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">9.6外汇兑换即期交易的要求。<br></span>除非{{ themeData.saasMerNameZh }}在提交非实时结算的即期外汇交易的客户请求之日或之前从客户的银行账户中收到相当于客户请求中所述卖出货币金额2%(该等金额可能会根据市场要求而有所不同，{{ themeData.saasMerNameZh }}应至少7个工作日的提前通知客户。)的卖出货币金额作为客户保证 金，否则{{ themeData.saasMerNameZh }}没有义务与客户进行任何外汇交易和/或付款。<br>
          </p>
        </div>
        <p class="navigationTitle">10.付款服务<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">10.1您对付款的指示。<br></span>您可以通过提供{{ themeData.saasMerNameZh }}平台上提示的必要信息来指示我们进行付款。如果某项付款涉及到支持货币之间的货币转换（例如，源货币与支付货币不同），我们还将向您提供外汇兑换服务，作为支付服务的附属服务。每笔付款，包括任何带有外汇兑换的付款，都是您和{{ themeData.saasMerNameZh }}之间的一个单独合同。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">10.2付款确认。<br></span>当我们确信您的指令是完整的，我们将向您发出有关付款的确认。如果您在结算截止日前没有足够的资金，或者您超过了任何适用的限额，或者根据第19条，我们可能会拒绝您的指令。一旦确认发出，您不能取消付款，并被视为最终确认。然后，您必须按照本协议条款的规定与我们进行全额结算。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">10.3付款服务的结算要求。<br></span>您必须以相关的源货币向备付金银行账户支付足够的资金，以支付您希望在结算截止日或之前作出的任何付款的全部金额。您可通过直接银行转账或其他我们接受的方式向我们付款。在不影响我们在本协议条款下的任何权利的前提下，您明确承认，如果您的备付金银行账户中没有足够的资金来支付全额款项，我们可以从您的全球账户中为您收集和持有的资金或您的{{ themeData.saasMerNameZh }}平台中的任何余额中扣除或抵消该款项。如果您以非付款货币的货币转入备付金银行账户，或如果您的全球账户中的资金或您的{{ themeData.saasMerNameZh }}平台中的任何货币（我们用于抵 销）不是付款货币，您授权我们在付款日按我们的提出请求时可获得的标准汇率将该货币兑换成付款货币。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">10.4付款服务的执行时间。<br></span>如果您要求我们进行付款，我们将在合理可行的情况下尽快进行支付，在任何情况下，即不超过我们接受您的指示后24小时内。贷记资金的方法可能由第三方（例如，收款人的银行账户所在的银行）提供，而不是我们服务的一部分。我们无法控制收款人的银行或支付提供商向收款人贷记资金所需的时间，但一般情况下，我们预计这需要少于5个工作日(除非我们要求更长的时间以符合适用法律)。如果付款确认上指定的日期不是工作日，那么该日期将被调整到下一个最近的工作日的第一天。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">10.5付款服务的指令<br></span>

            (a)客户应通过{{ themeData.saasMerNameZh }}平台提交与付款有关的客户请求，其中包括但不限于以下信息：(i)为客户规定的"了解您的客户"("KYC")详情；(ii)为付款人和收款人规定的"KYC"（“了解你的客户”）详情；(iii)收款人的银行账户详情；(iv)源货币和付款货币；(v) 付款金额；(vi)付款日期；(vii)指定的付款方式(如果没有指定付款方式，{{ themeData.saasMerNameZh }}保留使用任何付款方式处理付款的权利)。<br>
            (b)当{{ themeData.saasMerNameZh }}认为客户已经提供了足够的细节、证明文件和信息以完成付款确认时，付款才会成立并具有约束力。<br>
          </p>
        </div>
        <p class="navigationTitle">11.撤消和拒绝<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">11.1撤销。<br></span>我们可能需要对与服务有关的任何资金进行撤销，并扣除我们已收到的作为服务一部分的资金，或退还我们已收到的作为服务一部分的资金，包括以下情况：<br>

            (a)汇款人或任何相关的支付服务提供商撤销交易或可能撤销交易；<br>
            (b)汇款人误将钱转给了您;<br>
            (c)您向我们提供了错误的交易指令；<br>
            (d)我们怀疑某项交易是欺诈性的，或违反了本协议条款或适用法律；<br>
            (e)我们根据第19条行使我们的权利。<br>

            您必须按照第12.5条的规定，尽快向我们补偿。如果我们撤销交易，我们将自行决定按撤销时适用的外汇汇率或适用于该交易的原始外汇汇率进行。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 11.2拒绝的交易。<br></span>在以下情况下，我们可能会扣留、拒绝或延迟接收任何收到、交换或发送的资金：<br>

            (a)我们的代理银行合作伙伴、流动资金提供者或其他金融机构没有提供有效的汇率；<br>
            (b)超过任何有关的反洗钱或打击资助恐怖主义行为的限制；<br>
            (c)您没有遵守本协议条款、可接受使用政策或适用法律规定的义务；<br>
            (d)我们根据第19条行使我们的权利。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">11.3失败的交易。<br></span>如果由于我们合理控制范围以外的原因（如资金不足），我们可以自行决定取消任何外汇兑换或付款，或通过将其滚动到下一个最近的兑换日或付款日（如适用）来延迟任何外汇兑换或付款，而无需对您负责。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 11.4资金不足。<br></span>如果在结算截止日时，有一笔或多笔外汇兑换和/或付款的资金不足，{{ themeData.saasMerNameZh }}保留有权进行外汇兑换或付款的权利（例如，按时间计算，将最早预订的外汇兑换或付款兑换至{{ themeData.saasMerNameZh }}在适用的兑换日或付款日的可用资金价值）。其余已预订的外汇兑换将被视为失败交易，并将产生相关的中断成本和管理费用。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 11.5使用正确的信息转账<br></span>

            (a)在每一种情况下，您都需要确保使用正确的银行信息和{{ themeData.saasMerNameZh }}列明的唯一交易参考号码信息将资金转给我们。如果您没有提供准确的信息，那么我们可能无法收到资金并将金额记录在相关账户中。在这种情况下，虽然我们会尽合理的努力调查并将错误的和不准确的付款存入或从您的账户中返还，但我们不会对您可能遭受或产生的任何损失负责。<br>
            (b)如果客户请求中包含的错误付款指令与之前的外汇交易有关，当客户因提供错误的付款指令而选择取消客户请求，则客户应承担通过{{ themeData.saasMerNameZh }}平台预订撤销外汇交易的费用。<br>
            (c)如果客户因错误的付款指令而取消客户请求，并提交新的客户请求以退回客户资金，<br>
            {{ themeData.saasMerNameZh }}不保证{{ themeData.saasMerNameZh }}退回的金额或货币与付款货币和/或金额一致。<br>
            (d)平台在客户请求中提供了错误的外汇交易指示（可能包括但不限于错误的结算日期、无效的货币配对和/或错误的买入货币或卖出货币金额），客户同意在可行的情况下尽快向{{ themeData.saasMerNameZh }}提供正确的客户请求，或取消该客户请求。在取消客户请求后，客户必须向{{ themeData.saasMerNameZh }}提供新的客户请求，以便将错误的客户请求所涉及的客户资金返还给客户。<br>
            (e)如果{{ themeData.saasMerNameZh }}无法从相关代理银行或流动性提供者处获得有效的利率，{{ themeData.saasMerNameZh }} 有权拒绝客户的更正请求。<br>
            (f)客户应赔偿{{ themeData.saasMerNameZh }}因客户未遵守本条款规定的程序而直接导致的任何付款失败而遭受的任何合理损失。<br>

          </p>
          <p class="detail">
            <span class="detailTitle"> 11.6超额资金。<br></span>当您向备付金银行账户付款时，您必须就付款或外汇兑换向我们发出准确及完整的指令。如您没有向我们提供准确或完整的指令，我们可自行决定：<br>
            (a)将资金退回给汇款人；<br>
            (b)按照通知我们的方式将资金汇入您绑定的银行账户；<br>
            (c)在符合任何适用法律的情况下，以任何其他方式将资金汇给您。<br>
          </p>
        </div>
        <p class="navigationTitle">12.安全和保障<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">12.1保护您的{{ themeData.saasMerNameZh }}账户的安全。<br></span>为了使用{{ themeData.saasMerNameZh }}平台，您（或您的授权用户）必须使用唯一的密码和任何多重因素认证来登录：例如，我们可能会要求您的授权用户在登录{{ themeData.saasMerNameZh }}平台时，或在使用{{ themeData.saasMerNameZh }}平台进行某些交易时，验证他们是授权用户。您必须始终安全可靠地保存所有登录信息和密码以访问{{ themeData.saasMerNameZh }}平台，并只允许授权用户访问服务。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 12.2如果您怀疑您的{{ themeData.saasMerNameZh }}账户资料可能被泄露，请联系我们。<br></span>如果您怀疑您的密码被泄露，请立即发送电子邮件至 {{ themeData.email }} 并更改密码。{{ themeData.saasMerNameZh }} 会对您的要求作出即时反应（即邮件汇报前述泄露的30分钟内）<br>

            (a)您的{{ themeData.saasMerNameZh }}账户，或进入{{ themeData.saasMerNameZh }}平台或其他安全凭证被窃取、遗失、未经您的授权而使用，或以其他方式受到损害；<br>
            (b)别人发现您的{{ themeData.saasMerNameZh }}账户或用户资料的登录信息和密码。<br>

            任何不适当的延迟通知我们的行为可能会影响您的{{ themeData.saasMerNameZh }}账户的安全，或影响您访问{{ themeData.saasMerNameZh }}平台，并可能导致您对任何经济损失负责。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">12.3请定期查看您的账户交易情况，您的所有活动都会在{{ themeData.saasMerNameZh }}平台上显示。<br></span>如果您怀疑或实际发生了未经授权的交易或其他有关{{ themeData.saasMerNameZh }}平台的安全问题，您必须立即发送电子邮件至{{ themeData.email }} 联系我们。如果交易是由于我们的错误、欺诈或您的交易错误造成的，我们可能会退还您的损失（见第12.4条）。如果您没有立即通知我们任何未经授权、或错误发起或执行的交易，您可能会失去要求纠正问题或退款的权利。在以下情况下，您也可能无法要求退款：<br>

            (a)您违反了本协议条款或任何适用法律；<br>
            (b)您向我们提供了错误的交易指示（在这种情况下，我们可能会在可能的情况下合理地帮助您收回资金，但我们不保证会成功）；<br>
            (c)任何损失都不是我们的行为或疏忽直接造成的。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 12.4未经授权的交易。<br></span>对于在{{ themeData.saasMerNameZh }}直接控制范围之外的未经授权的交易，我们不承担任何责任。特别是在下述情形（但不限于此），我们不承担任何责任（而您将对所有损失负全部责任）：<br>

            (a)如果您不及时通知我们安全问题；<br>
            (b)如果我们发现您或您的授权用户或代表您的其他任何人有欺诈的行为；<br>
            (c)如果 您故意或因严重疏忽而损害了您的{{ themeData.saasMerNameZh }}账户或{{ themeData.saasMerNameZh }}平台的安全，或您未能按照本协议条款履行使用这些数据的义务，以及未能保持所有登录数据和密码保密和安全；<br>
            (d)如果您给了我们错误的付款指示（我们可能会合理地帮助您收回资金，但我们不保证会成功）；<br>
            (e)在适用法律允许的范围内，在我们直接控制之外的任何其他情况下。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 12.5偿还。<br></span>如果我们向您退还未经授权交易的款项，但随后发现您没有遵守本条款第12条规定的义务，您必须向我们偿还未经授权交易的价值。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">12.6确认。<br></span>您认可：<br>

            (a)对于您通过{{ themeData.saasMerNameZh }}平台进行的任何交易，{{ themeData.saasMerNameZh }}不承担任何责任，也不作任何保证；<br>
            (b)您必须获得并使用您使用或连接{{ themeData.saasMerNameZh }}平台所需的任何设备或电信线路和连接；<br>
            (c)您使用的某些软件和设备可能无法支持{{ themeData.saasMerNameZh }}平台的某些功能；<br>
            (d)您有责任配置和更新您的信息技术、软件和设备，以便使用本服务或{{ themeData.saasMerNameZh }}平台。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">12.7您的责任。<br></span>您应对{{ themeData.saasMerNameZh }}因以下原因或与以下原因有关而遭受或产生的所有合理成本、收费、索赔、损害赔偿、费用、损失、支出、罚款和责任负责：(i) (如适用)您的错误指示、超额支付、支付错误或您导致的其他无效支付；(ii) 您、您的雇员、董事、高级职员或代表或任何代表您的人的任何错误、违约、疏忽、不当行为或欺诈行为；以及(iii) 因您未能遵守本协议条款，包括但不限于本条款第12条，而使{{ themeData.saasMerNameZh }}遭受或招致的任何成本、收费、索赔、损害赔偿、费用、损失、开支、罚款和责任。<br>
          </p>
        </div>
        <p class="navigationTitle">13.费用和收费<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">13.1服务费。<br></span>您将为服务向我们支付服务费，或者根据载于{{ themeData.website }}的费用表，或根据与我们另有书面约定。服务费根据您所使用的服务而单独收取，例如：<br>

            (a)收款服务。收款服务的费用一般包括按资金的一定百分比收费和/或按每笔交易、和/ 或按每日、每周、每月或每年等周期收取的固定费用；<br>
            (b)付款服务。付款服务的服务费一般都会涉及到费用，这取决于您是使用本地渠道还是SWIFT支付渠道支付和/或按每笔交易、和/或按每日、每周、每月或每年等周期收取的固定费用；<br>
            (c)外汇兑换服务。外汇兑换服务的服务费一般涉及在外汇基本汇率之上收取客户保证金和/或按每笔交易、和/或按每日、每周、每月或每年等周期收取的固定费用；<br>
            (d){{ themeData.saasMerNameZh }}平台服务。可能会按一定时间收取服务费，例如因商户使用{{ themeData.saasMerNameZh }}平台服务向{{ themeData.saasMerNameZh }}发起充值、提现、查询、对账、个性化设置等系统的或电子指 令，按月收取。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">13.2服务费的变更。<br></span>除非我们以书面形式另行同意，否则我们可以随时修改服务费，修改后的服务费将在我们通知您服务费适用的日期生效。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">13.3税。<br></span>根据本协议条款应付给我们的所有款项：<br>

            (a)不包括现在生效或将来颁布的所有税费和类似费用，所有这些费用您都将负责并全额支付；<br>
            (b)除非适用法律禁止，否则将全额支付，不得有任何抵销、索赔、扣减或预扣。如果适用法律要求您从任何付款中扣除预扣税：(i) 您应立即通知{{ themeData.saasMerNameZh }}有关要求；(ii) 双方应进行所有必要的申报，以确保任何适用的税务条约的规定适用于该笔付款；(iii) 您应向{{ themeData.saasMerNameZh }}支付额外的金额，使以{{ themeData.saasMerNameZh }}收到在没有预扣或扣除的情况下本应收到的全部金额。(iv) 您将向相关机构支付到期的全额扣款或预扣款；以及 (v) 您将立即向{{ themeData.saasMerNameZh }}提交正式收据（或经核证的副本）或{{ themeData.saasMerNameZh }}合理接受的其他文件，以证明已向该机构支付款项。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">13.4支付欠款的义务。<br></span>您必须在7个工作日内向我们支付任何欠款，包括因任何撤销、退款、费用、收费或其他交易而导致的欠款，如逾期，则我们可能会暂停您对服务的使用（但不包括对任何相关账户的访问），直到我们收到该等付款。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">13.5扣除您欠我们款项的权力。<br></span>我们可以不时从我们收取或收到的作为服务的一部分的资金中，或从根据本协议条款第13.6条提供的作为担保的任何金额中扣除您欠我们的任何金额。如果您没有足够的资金来支付您欠我们的款项，我们可以在您承担费用的情况下，采取合理的步骤来追回这笔款项（例如采取法律行动或使用追债服务）。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">13.6担保。<br></span>我们可以在任何时候要求您在收到我们的书面请求后30天内（或我们可能确定的更长时间），按我们的合理要求以我们合理要求的形式和资产获得担保或赔偿（包括替换任何现有的担保），以确保我们合理满意地履行您根据本协议条款不时承担的义务（包括或有或潜在的义务）。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">13.7服务费的支付方式<br></span>

            (a)对于每笔单笔交易中发生的费用：当每笔交易被{{ themeData.saasMerNameZh }}成功确认后，{{ themeData.saasMerNameZh }} 将根据扣款时外汇市场的汇率，直接从客户的预存款账户余额中以实际扣款币种扣除等值的费用金额（若实际费用币种与扣款币种相同，则不发生外汇兑换）。当余额不足时，客户有义务及时补足，并对因余额不足造成的问题负责。<br>
            (b)对于固定费用。{{ themeData.saasMerNameZh }}会根据扣除时外汇市场的汇率，于每月一(1)日从客户的预存款账户余额中以交易的实际借记币种扣除任何固定费用金额（若实际费用币种与借记币种相同，则不发生外汇兑换）。当余额不足时，客户有义务在被通知后的5个工作日内补足，并对因余额不足造成的问题负责。<br>
            (c)安装费和其他一次性费用。{{ themeData.saasMerNameZh }}将向客户发出安装费和其他一次性费用的发 票，客户应在每月第一(1)日继续使用任何服务前全额支付。客户应接受发票并立即将款项汇入发票上指定的收款银行账户。<br>
          </p>
        </div>
        <p class="navigationTitle">14.知识产权<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">14.1许可证。<br></span>让您在遵守本协议条款的前提下，我们授予您一个个人的、可撤销的、不可转让的、不可转授的和非独家的许可以通过任何支持的网络浏览器进入和使用{{ themeData.saasMerNameZh }}平台，以管理我们的服务。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">14.2所有权。<br></span>我们拥有{{ themeData.saasMerNameZh }}平台及其专有技术的所有知识产权，包括其软件（源码和对象形 式）、算法、用户界面设计、架构和文件（包括印刷的和电子的）、网络设计，以及包括任何修改、改进和衍生作品。本协议条款并不意味着{{ themeData.saasMerNameZh }}向您转移{{ themeData.saasMerNameZh }}平台和/或其专有技术的任何所有权。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">14.3使用限制。<br></span>除了在第14.1条许可授权使用我们的服务的权利外，您对我们的服务（包括{{ themeData.saasMerNameZh }}平台）不拥有任何权利。除非法律要求或{{ themeData.saasMerNameZh }}允许，否则您不得复制、修改、改编、翻译、创作衍生作品、转让、出借、出租、再许可、出售、构架或以其他方式重新发布或重新分发、公开表演或公开展示{{ themeData.saasMerNameZh }}平台、服务或所含软件的任何部分。您不得允许任何未经授权的人进入或使用{{ themeData.saasMerNameZh }}平台，或在{{ themeData.saasMerNameZh }}平台进行投机性交易。除适用法律明确允许外（但不是其他情况），您也不能对{{ themeData.saasMerNameZh }}平台或软件进行反向工程、反编译、反汇编或试图提取其源代码。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">14.4使用您的数据。<br></span>{{ themeData.saasMerNameZh }}仅仅履行本协议条款规定的义务的必要性范围内使用您的任何数据，且非经您的书面批准，不会向任何第三方披露。您应对通过{{ themeData.saasMerNameZh }}平台传输的任何客户数据和支付数据的质量、准确性和完整性负全部责任。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">14.5其他人的权利。<br></span>您不得以任何方式使用包括{{ themeData.saasMerNameZh }}平台在内的我们的服务去侵犯或违反我们或其他任何人的版权、商标或其他知识产权，或以其他方式违反任何适用法律。{{ themeData.saasMerNameZh }}平台可能会显示我们并无所有权的内容，我们对此不负任何责任。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">14.6宣传。<br></span>除非获得您的事先许可，{{ themeData.saasMerNameZh }}不会使用您的商业信息纳入{{ themeData.saasMerNameZh }}服务的一般宣传材料中。<br>
          </p>
        </div>
        <p class="navigationTitle"> 15.数据的保密、隐私和使用<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">15.1保密信息。<br></span>您承认，您可能会接触到属于我们的保密信息，您必须根据本协议条款的规定对这些信息进行保密。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.2保密承诺。<br></span>您同意，您：<br>

            (a)不会向任何第三方披露我们的任何保密信息，除非另有要求：<br>
            (i)法律或任何有管辖权的机构要求；(ii)在合理必要的情况下向您的律师、会计师和其他顾问提供；或(iii)为了在实际或威胁的诉讼中为自己辩护；<br>
            (b)将采取合理的预防措施来保护这些信息的机密性，至少要与保护您自己的保密信息的措施一样严格。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.3向雇员和代理披露。<br></span>此外，您只能向您的代理人、代表和雇员透露我们的保密信息，这些代理人、代表和雇员"有必要知道"与本协议条款有关的信息，他们被告知这些保密信息的保密性质，并同意按照第15条的条款和条件行事。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.4隐私和个人数据。<br></span>保护个人数据对我们非常重要。除了本协议条款外，我们的隐私政策还解释了我们如何以及出于何种目的收集、使用、保留、披露和保护您向我们提供的个人数据。您同意查看我们不时更新的隐私政策条款。<br>

            <a-badge status="default" />目的。在合法的范围内，您同意与{{ themeData.saasMerNameZh }}分享个人数据，并授权{{ themeData.saasMerNameZh }}收集、使用、储存或以其他方式处理该等个人数据作以下“协议目的”，使我们能够：<br>
            <a-badge status="default" />协助向您提供有关产品或服务的信息；<br>
            <a-badge status="default" />考虑您对产品或服务的要求；<br>
            <a-badge status="default" />使我们能够提供产品或服务；<br>
            <a-badge status="default" />管理{{ themeData.saasMerNameZh }}与客户的关系；<br>
            <a-badge status="default" />管理账户和执行其他管理和业务任务（包括风险管理、系统开发和测试、信用评分、员工培训、催收债务和市场或客户满意度调查）<br>
            <a-badge status="default" />设计新的产品和服务或改进我们提供的现有产品和服务；<br>
            <a-badge status="default" />我们与您之间的一般和特定的通信；<br>
            <a-badge status="default" />考虑您对我们提出的任何疑虑或投诉，管理涉及{{ themeData.saasMerNameZh }}的任何法律行动，或两者；<br>
            <a-badge status="default" />进行任何执行活动，包括向您收取任何款项；<br>
            <a-badge status="default" />根据任何当地或外国的法律、法规或条约，或根据与任何税务机关的协议，确定您的身份或确立您的税务地位；<br>
            <a-badge status="default" />识别、防止或调查任何欺诈、非法活动或不当行为（或涉嫌欺诈、非法活动或不当行为）；<br>
            <a-badge status="default" />遵守所有适用法律，包括进行反洗钱、金融犯罪和其他检查；<br>
            <a-badge status="default" />核实与您有关的任何信息和记录；<br>
            <a-badge status="default" />遵守本协议条款和隐私政策；<br>
            <a-badge status="default" />使我们全部或任何部分业务及/或资产的实际或潜在受让人能够评估拟作为转让标的的交易，并使实际受让人能够在业务运营中使用您提供的个人数据；<br>
            <a-badge status="default" />用于我们的隐私政策中规定的任何其他目的。<br>

            您可以随时通过书面（包括Email）至第2.2条所记载的联系方式处，撤销前述授权。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.5数据主体。<br></span>我们可能收集的数据主体类别可能包括以下自然人：客户、客户的董事和最终受益所有人、您的客户、雇员和承包商、付款人和收款人。您可能会与{{ themeData.saasMerNameZh }}分享因服务而了解到的下列有关数据主体的部分或全部个人数据。<br>

            <P style="margin-left: 20PX;margin-top: 10px">
              全名；<br></p>
            <P style="margin-left: 20PX">
              电子邮件地址；<br></p>
            <P style="margin-left: 20PX">
              电话号码和其他联系信息；<br></p>
            <P style="margin-left: 20PX">
              出生日期；<br></p>
            <P style="margin-left: 20PX">
              国籍；<br></p>
            <P style="margin-left: 20PX">
              关于数据主体的公共信息<br></p>
            <P style="margin-left: 20PX">
              本协议条款所要求的其他相关核查或尽职调查文件；以及<br></p>
            <P style="margin-left: 20PX">
              执行协议目的所需或相关的任何其他数据。<br></p>
          </p>
          <p class="detail">
            <span class="detailTitle">15.6合规。<br></span>任何一方都不会故意履行其在本协议条款下的义务而导致另一方违反适用的数据保护法规。对于另一方未能遵守与其共享的个人数据有关的适用数据保护法规所导致的任何后果，双方均不承担责任。<br>
            (a)您承诺每一笔通过{{ themeData.saasMerNameZh }}完成的支付交易信息均真实有效，具有真实合法的交易背景，确保结算资金来源和流向的合法合规性。并应当按照{{ themeData.saasMerNameZh }}规范要求向{{ themeData.saasMerNameZh }}提交可证明交易真实性的每笔交易明细，包括但不限于交易双方信息、交易商品名称、交易商品数量、交易时间、交易金额、付款信息、物流信息、消费者签收回执单或协议等信息，因您伪造交易明细及合作资料所产生的一切责任由您独立承担。<br>
            (b)您应对所有通过{{ themeData.saasMerNameZh }}所产生的交易信息及资料文件承担保存义务，在本协议有效期内本协议终止后至少5年内（若法律法规有更长时间的规定，则以法律法规规定为准）保存所有的尽职调查文件。您需确保在接到我们通知或在香港打击洗钱条例的保存记录所列明的期间做出要求时，您应在合理切实可行的范围内，尽快向我们提供您在履行尽职调查措施时取得的任何文件、数据或资料的记录。<br>
            (c)我们会不定时进行抽查，您应在我们要求时尽快提供尽职审查的资料或文件。<br>
            (d)我们有合理理由怀疑您的可靠性，我们可当即采取措复核您履行尽职调查的能力。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.7承诺。<br></span>在您或{{ themeData.saasMerNameZh }}处理个人数据的范围内，每一方将：<br>

            (a)公平、合法地处理个人数据，并确保其在数据保护法规下有合法理由进行处理；<br>
            (b)获得并维持数据保护法规所要求的所有适当的注册，以允许其根据本协议条款使用个人数据；<br>
            (c)确保采取适当的技术和组织安全措施，以保护其控制下的个人数据；<br>
            (d)确保所有能够接触和/或处理个人数据的人员有义务对其进行保密；<br>
            (e)只有在采取适当措施使数据保护法规定的转移合法的情况下，才会在不同的司法管辖区之间转移个人数据；<br>
            (f)如果收到数据主体或监管机构向另一方提出的或针对另一方的任何请求、投诉或其他通信，应立即通知对方；<br>
            (g)如果发现与对方直接相关的个人数据泄露，应及时通知对方。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">15.8数据安全。<br></span>除了我们规定的任何其他合理的信息安全要求外，您将确保传输给{{ themeData.saasMerNameZh }}的任何个人数据的方法和手段是安全的，并符合数据保护法规。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.9合作。<br></span>各方将负责回应监管机构或个人根据适用的《数据保护法》规行使其权利的请求。各方将向对方提供合理的合作和协助，以遵守数据保护法规和任何相关适用法律对其规定的所有义务，包括： (i) 处理及回应数据主体的任何通讯；(ii) 处理、减轻及回应任何个人数据泄露事件；及(iii) 就安全、影响评估及与管理机关或监管机构磋商。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.10数据处置。<br></span>在本协议条款终止时，或一旦处理任何个人数据不再是执行目的所必需的，各方将根据适用法律的要求安全地处置其拥有的任何此类个人数据。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.11数据安全。<br></span>我们将采取数据保护法规所要求的一切步骤，确保对此类披露采取适当的信息安全保障措施。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">15.12您提供的有关他人的个人数据。<br></span>如果您向我们提供有关他人的个人数据，包括（但不限于）您的授权用户、您的董事和最终受益所有人、您的客户、雇员和承包商、付款人和收款人，您承诺我们可以依赖您获得该等人士的同意，向我们披露他们的个人数据，我们将根据本协议条款的规定使用这些资料。您须向该等人士出示有关本条款的信息，以便该等人士了解，在您与我们的交易中，{{ themeData.saasMerNameZh }}可能收集、使用及披露其个人数据的方式，以及我们对其个人数据的使用。<br>
          </p>
        </div>
        <p class="navigationTitle">16.披露信息和使用个人数据进行直接营销<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">16.1我们可能会向其他人披露您的信息。<br></span>{{ themeData.saasMerNameZh }}可能会将您的客户数据、支付数据和个人数据，包括任何保密信息披露给：<br>

            <a-badge status="default" />{{ themeData.saasMerNameZh }}的任何分公司、子公司、控股公司、关联公司、附属公司或相关实体；<br>
            <a-badge status="default" />我们聘请或建议聘请的任何代理人、承包商或服务提供商，以执行或协助我们的职能和活动，并有责任对这些信息保密；<br>
            <a-badge status="default" />支付系统的参与者，包括卡组织、金融机构和支付组织，如环球银行间金融电信协会；<br>
            <a-badge status="default" />{{ themeData.saasMerNameZh }}可能与之签订任何转让、融资、股份购买或其他商业协议，并有义务对该信息保密的任何人；<br>
            <a-badge status="default" />任何适用法律或法规或根据任何法院命令允许或要求（或预期）向其披露信息的任何人；<br>
            <a-badge status="default" />任何推荐人或代表或您的代表（包括任何专业顾问、经纪人、介绍人、律师或执行人）；<br>
            <a-badge status="default" />抵押、担保或信贷支持的任何第三方提供者（如有）；<br>
            <a-badge status="default" />信用调查机构，以及在发生违约的情况下，给追债机构；<br>
            <a-badge status="default" />您授权的或根据法律授权的任何代理人，如破产管理人或受信托人或您的法定代表人；<br>
            <a-badge status="default" />我们全部或部分业务和/或资产和/或{{ themeData.saasMerNameZh }}权益的任何实际或建议受让人。<br>
          </p>
        </div>
        <p class="navigationTitle">17.责任限制<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">17.1赔偿责任。<br></span>本协议条款中的任何内容都不排除或限制任何一方当事人的责任，如果这样做是非法的。这包括一方当事人的以下责任：<br>

            (a)因其过失造成的死亡或人身伤害；<br>
            (b)欺诈或欺诈性的虚假陈述；<br>
            (c)根据本协议条款，所有到期应付的款项。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">17.2直接损失。<br></span>根据本协议条款，直接损失可予赔偿。然而，除非本协议条款中另有规定，任何一方都不对任何间接损失负责。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">17.3不适用。<br></span>我们将不对您承担以下责任：<br>

            (a)任何在我们直接控制范围之外的损失，这些损失是由任何第三方代理银行、流动性提供者或属于用于提供服务的支付网络的其他金融机构的疏忽、欺诈或故意不当行为或破产引起的。<br>
            (b)如果您在您的指令中提供的任何信息是不正确的导致一笔付款未执行或有缺陷的执 行；<br>
            (c)如果收款人/收款银行未能正确处理付款，则由收款人/收款银行引起的错误、失误或不履行义务。<br>

            在(b)或(c)的情况下，我们将做出合理的努力来追回支付所涉及的资金。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">17.4累积责任。<br></span>除第17.5条规定外，{{ themeData.saasMerNameZh }}就本协议条款引起的或与本条款有关的所有诉讼对您所承担的总责任不超过您向{{ themeData.saasMerNameZh }}支付的服务费总额。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">17.5外汇兑换的责任。<br></span>在适用法律允许的最大范围内，{{ themeData.saasMerNameZh }}对外汇兑换的最大责任，无论是否因合同、侵权行为或其他原因引起，在任何情况下都不会超过{{ themeData.saasMerNameZh }}在该外汇兑换中出售的货币金额。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">17.6公平救济。<br></span>单纯的损害赔偿可能不是对违约行为的充分补救，因此，任何一方都有权对任何可能或实际的违约行为寻求禁止令、具体履行或其他公平救济等补救 措施。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">17.7减轻损失。<br></span>各方同意作出合理的努力，以减轻根据本协议条款应予赔偿的任何损失。<br>

          </p>
        </div>
        <p class="navigationTitle">18.赔偿<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">18.1客户的赔偿。<br></span>应要求，您将赔偿我们因以下原因而招致或遭受的所有损失、损害、费用（包括合理的法律费用）和开支：<br>

            (a)您违反本协议条款、未遵守适用法律、未遵守可接受使用政策、或您使用或滥用我们的服务；<br>
            (b)您的任何付款或外汇兑换，包括我们按您的任何指示行事，而我们有理由相信该等指示是由您或您的授权用户作出的；<br>
            (c)在兑换日或付款日之前关闭或取消全部或部分外汇兑换或付款，包括因您未能为外汇兑换或付款提供资金而我们不得不这样做的情况。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">18.2第三方索赔。<br></span>第18.2条、第18.3条和第18.4条适用于第18条和第22条的义务。如果一方意识到有任何可能导致根据第18条或第22条提出索赔的索赔或诉讼（各自称为"第三方索赔"），该方将立即以书面形式通知另一方，在另一方始终遵守第18.3条的前提下，第一方将：<br>

            (a)允许另一方控制对此类第三方索赔的辩护；<br>
            (b)应另一方的要求和提供支出，为另一方提供合理的援助，以维护任何此类第三方索赔。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">18.3辩护。<br></span>如果对方承担第三方索赔的抗辩，对方将：<br>

            (a)迅速通知第一方当事人，它已经承担了这种辩护；<br>
            (b)在任何时候都要考虑到第一方的利益和声誉，并遵守所有适用的法院规则，为此类第三方索赔进行辩护；<br>
            (c)就任何谈判、和解或诉讼与第一方协商，并随时向其通报；<br>
            (d)未经第一方事先书面同意，不得就第三方索赔达成任何涉及支付金钱以外的补救措施的和解或妥协。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">18.4赔偿。<br></span>如果另一方在接到第三方索赔的书面通知后14天内没有根据第18.3条承担第三方索赔的辩护，则第一方或第一方指定的人可以其认为合适的方式为第三方索赔辩护，另一方将应要求赔偿相对方因该第三方索赔而产生的合理费用。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">18.5赔偿条件。<br></span>只有在以下情况下，本条款第18条规定的义务才适用：被赔偿方在得知或收到索赔后三十(30)天内以书面形式通知赔偿方；被赔偿方，在费用由赔偿方承担的情况下，向赔偿方提供赔偿方所要求的合理协助，用于任何索赔的辩护和和解(如适用)；被赔偿方向赔偿方提供控制和解决任何索赔的专属权利和权力。<br>
          </p>
        </div>
        <p class="navigationTitle">19.协议终止和中止<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">19.1关闭您的{{ themeData.saasMerNameZh }}账户。<br></span>您可以通过停止使用服务的方式关闭您的{{ themeData.saasMerNameZh }}账户，并在任何时候提前30个日历日以书面形式通知我们终止本协议条款。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">19.2:{{ themeData.saasMerNameZh }}有权中止或终止。<br></span>我们可以随时通过书面通知您提前60个日历日终止本协议条款<br>
            （或任何其他条款）。此外，在下列情况下，我们可在不发出通知的情况下，立即暂停或终止全部或部分条款、任何附加条款或任何服务，或阻截任何交易。<br>
            (a)我们怀疑与您的{{ themeData.saasMerNameZh }}账户有关的犯罪活动，怀疑您的{{ themeData.saasMerNameZh }}账户被欺诈性使用，或我们有理由相信您曾因未经授权的交易而欺诈性地要求退款。<br>
            (b)我们有理由相信您违反了适用法律。<br>
            (c)我们在法律上需要这样做，包括按照任何相关监管机构的指示。<br>
            (d)您违反了本协议条款中的任何规定或违反了可接受使用政策中的任何适用限制或约束。<br>
            (e)您向我们提供了虚假或不准确的信息，或者我们无法核实您提供的任何信息。<br>
            (f)您通知我们，或者我们怀疑或识别任何可疑或实际的未经授权的交易。<br>
            (g)您已被列入任何国家或国际限制或禁止的名单中，从而使我们无法与您开展业务。<br>
            (h)您没有接受对本协议条款的变更；<br>
            (i)我们因技术或安全等原因暂停或停止全部或部分服务；<br>
            (j)我们对您的{{ themeData.saasMerNameZh }}账户，包括对服务的使用有合理的担忧；<br>
            (k)我们的银行合作伙伴之一，或提供服务所需的其他服务提供商要求我们终止本协议条款；<br>
            (l)如果{{ themeData.saasMerNameZh }}根据自己的决定认为客户为任何非法、破坏性或未经授权的目的利用MSP，可以在提前一天通知客户的情况下暂停或终止客户对商户服务平台的访问。此外，如果发生以下任何一种情况，{{ themeData.saasMerNameZh }}将保留终止服务的权利：(i)商户服务平台的全部或部分故障，包括构成商户服务平台的技术故 障；(ii)MSP的安全性受到破坏；(iii)客户严重违反本协议规定的支付义务；或(iv)为了遵守适用法律。<br>
            (m){{ themeData.saasMerNameZh }}根据第19.2条采取的任何行为应在{{ themeData.saasMerNameZh }}自行决定的必要时间内继续进行。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">19.3在可能的情况下，我们会向您发出暂停通知。<br></span>我们会尽快向您发出任何暂停的通知以及暂停的原因，无论是在实施暂停之前，还是在暂停之后立即通知您，除非这样做会损害我们合理的安全措施或在其他方面是非法的。我们将在暂停的原因不复存在后，在切实可行的情况下尽快解除对服务的暂停。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">19.4立即终止。<br></span>在不影响任何其他权利或补救措施的情况下，任何一方均可在书面通知对方后立即终止本协议条款或任何附加条款，且无需对对方承担任何责任。<br>

            (a)如果对方:<br>
            <span class="use-two-title">(i)严重违反本协议条款（或附加条款），并且如在能够补救的情况下，未能在收到正式书面通知后30天内补救该违约行为；<br></span>
            <span class="use-two-title">(ii)一系列违反本协议条款（或附加条款）的行为，这些行为加在一起会产生影响或效果，或以其他方式构成重大违约；<br></span>
            <span class="use-two-title">(iii)成为破产事件的主体。<br></span>
            (b)如果一方合理地确定履行其在本协议条款下的义务已变得不合法；或发生不可抗力事件，导致另一方无法履行其在本协议条款下的任何义务，时间超过1个月。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">19.5终止时的还款。<br></span>终止时，您需要在30个自然日内偿还您欠我们的款项。在第19.2或19.4条规定的情况下，我们可能会向您收取我们产生的合理成本、费用和损失（包括因外汇差额或我们为弥补或减少风险而采取的任何行动而产生的费用）。我们会根据您的要求退还您在账户中的所有款项。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">19.6终止的效力。<br></span>在本协议条款或任何附加条款终止时：<br>
            (a)您将无法使用{{ themeData.saasMerNameZh }}平台（或该等附加条款下的服务），而根据本协议条款或附加条款授予您的所有权利将被终止。<br>
            (b)您必须立即归还或删除构成或关于{{ themeData.saasMerNameZh }}平台（或该等附加条款下的服务）的属于{{ themeData.saasMerNameZh }}的文件、笔记和其他材料的副本。<br>
            (c)您在本协议条款（或附加条款）下提供的服务至终止生效日的所有付款义务将立即到期并应当在60个自然日内支付。<br>
            (d)各方将在本协议条款或附加条款 终止后30天内归还或删除其掌握的另一方的所有保密信息，并且不会制作或保留该等保密信息的任何副本，除非为遵守适用法律而有必要；<br>
            (e)任何明示或暗示在终止后具有效力的条款将继续具有完全的效力和作用。<br>

            终止的其他理由。{{ themeData.saasMerNameZh }}可以在下列情况之一，在任何时候暂停服务并终止本协议，而无需事先通知：如果金融监管机构、监管机构或执法机构对客户发出警告或向{{ themeData.saasMerNameZh }}发出要求其停止服务的通知；任何政府、监管或司法机构指示{{ themeData.saasMerNameZh }}暂停或终止本协议；客户被列入联合国或其他国际机构发布的限制或禁止名单；或根据{{ themeData.saasMerNameZh }}经营所在国家的法律或法规，禁止{{ themeData.saasMerNameZh }}与客户开展任何业务；{{ themeData.saasMerNameZh }}的银行合作伙伴要求{{ themeData.saasMerNameZh }}终止本协议；或{{ themeData.saasMerNameZh }}自行判断认为客户从事虚假交易、欺诈、洗钱、卖淫、贩毒、恐怖融资或任何其他违反{{ themeData.saasMerNameZh }}经营业务的司法管辖区的适用法律或法规的活动。<br>
          </p>
        </div>
        <p class="navigationTitle">20.第三方服务供应商<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">20.1确认。<br></span>您承认并同意：<br>

            (a)我们可能与第三方（包括{{ themeData.saasMerNameZh }}集团的其他公司）合作提供服务；<br>
            (b)如果您收到我们提供的有关第三方的信息，或由该等第三方提供的信息，您不会依赖该等信息，也不会就因依赖该等信息向我们或第三方提出任何索赔。您同意，如果您选择在违反本条款的情况下依赖该等信息，您将承担所有风险和责任。<br>
            (c)对于客户通过{{ themeData.saasMerNameZh }}平台进行的任何交易，{{ themeData.saasMerNameZh }}不承担任何责任，也不作其他保证。<br>
            (d)客户使用的某些软件和设备可能无法支持{{ themeData.saasMerNameZh }}平台的某些功能。<br>
            (e)客户须对因以下原因而导致的所有费用、损害赔偿、损失、罚款及{{ themeData.saasMerNameZh }}的责任负责：(i)(如适用)客户的错误指示、超额支付、支付错误或客户造成的其他无效支<br>
            付；(ii)客户、客户的雇员、董事、高级职员、客户代表或任何代表客户的人的任何错误、或违约、疏忽、不当行为或欺诈；以及(iii)客户未能遵守本协议和本服务附表的条款而导致的任何费用、损害赔偿、损失、罚款和责任。<br>
            (f)对于因外汇交易的延迟、取消或失败而导致的服务汇率波动所造成的任何损失，<br>
            {{ themeData.saasMerNameZh }}不承担任何责任。<br>
            (g){{ themeData.saasMerNameZh }}有权在任何时候撤销、扣留或暂停向客户提供服务。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">20.2服务由{{ themeData.saasMerNameZh }}提供。<br></span>如果我们使用第三方提供服务，则您承认服务是由{{ themeData.saasMerNameZh }}向您提供的，您的合同关系的相对方依然是{{ themeData.saasMerNameZh }}，除非关于使用服务或服务功能的任何附加条款另有书面规定。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">20.3遵守数据保护法。<br></span>如果我们使用第三方提供服务，我们将确保该第三方遵守与根据本协议条款处理个人数据有关的数据保护法规。<br>
          </p>
        </div>
        <p class="navigationTitle">21.声明与保证<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">21.1客户保证。<br></span>您向我们声明并保证，您：<br>
            (a)您在使用服务、{{ themeData.saasMerNameZh }}平台和客户数据时，将遵守本协议条款和所有适用法律。<br>
            (b)有权利、权力和授权签订本协议条款，并根据本协议条款履行您的所有义务。<br>
            (c)在不违反任何适用法律或任何第三方的所有权的情况下，有权向我们授予本协议条款中定义的权利。<br>
            (d)根据本协议条款的第4条，将及时向我们提供准确、最新和完整的客户尽职调查信息和数据；<br>
            (e)本服务和{{ themeData.saasMerNameZh }}平台仅用于合法目的。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">21.2客户行为。<br></span>您对服务及{{ themeData.saasMerNameZh }}平台其他功能的任何使用，包括您本人或您允许访问{{ themeData.saasMerNameZh }}平台的任何授权用户通过{{ themeData.saasMerNameZh }}平台执行的任何数据或传输的内容，须负全责。您将尽一切合理的努力，确保没有未经授权的人会或可以接触您的{{ themeData.saasMerNameZh }}账户或{{ themeData.saasMerNameZh }}平台的其他功能。您不得干扰、破坏或对{{ themeData.saasMerNameZh }}平台或服务的其他用户造成任何损害。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">21.3您不能滥用{{ themeData.saasMerNameZh }}平台。<br></span>您不能:<br>

            (a)使用{{ themeData.saasMerNameZh }} APIs或MSP以外的方法访问{{ themeData.saasMerNameZh }}平台，例如，未经我们允许，您不能使用自动化手段。<br>
            (b)做任何可能破坏、禁用、过载或损害{{ themeData.saasMerNameZh }}平台的行为，如服务攻击；<br>
            (c)导致病毒或其他恶意代码干扰{{ themeData.saasMerNameZh }}平台的使用。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">21.4免责声明。<br></span>除本协议条款中明确规定外，所有的保证、条件和条款，无论是立法、普通法或其他法律所明示或暗示的（包括任何关于质量满意或适用于某一特定目的或不侵权的暗示保证），均在法律允许的范围内排除。特别是，我们：<br>

            (a)对于{{ themeData.saasMerNameZh }}平台的内容或数据、{{ themeData.saasMerNameZh }}平台的特定功能或其准确性、可靠性、可用性或满足您需要的能力，不作任何承诺；<br>
            (b)出于操作、安全或其他合理的原因，我们可能会偶尔中断您对{{ themeData.saasMerNameZh }}平台的使用。在这种情况下，我们会在可行的情况下尽快恢复访问。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">21.5财务建议。<br></span>您不得将{{ themeData.saasMerNameZh }}提供的任何信息或评论视为财务建议。您应考虑就任何该等信息或意见及使用服务寻求您自己的独立建议。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">21.6客户责任。<br></span>客户：<br>

            (a)对通过商户服务平台传输的客户数据的质量、准确性和完整性负全部责任；<br>
            (b)必须提供和/或获得使用商户服务平台所需的任何设备或电信线路和连接。<br>
            (c)在{{ themeData.saasMerNameZh }}提出要求后的两个工作日内，必须向{{ themeData.saasMerNameZh }}提供{{ themeData.saasMerNameZh }}要求的有关付款的任何额外信息。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">21.7电子身份检查<br></span>

            (a)客户承认并同意，{{ themeData.saasMerNameZh }}将获准进行电子数据库搜索和查询信用参考机构，以核实客户或其任何股东的身份和信用状况，以及是否符合所有了解您的客户（"KYC"） 要求和{{ themeData.saasMerNameZh }}经营所在司法管辖区的任何反洗钱/反恐融资法律（"AML/CTF"）。客户承认，在处理任何交易和执行任何服务之前，必须满足所有KYC要求。{{ themeData.saasMerNameZh }}可以根据所有现行的和适用法律或其他要求，保存此类搜索的内容和结果的记录。<br>
            (b)客户进一步承认并同意，{{ themeData.saasMerNameZh }}被允许根据相关的反洗钱/反恐金融法对付款人和收款人进行必要的KYC筛选和交易监控。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">21.8与反洗钱/反恐金融法律有关的资金处理。<br></span>如果第21.8条规定的电子身份检查触发{{ themeData.saasMerNameZh }} 通知监管机构的义务，{{ themeData.saasMerNameZh }}保留扣留、拒绝或延迟任何客户资金的外汇交易或付款的权利，以遵守{{ themeData.saasMerNameZh }}经营所在司法管辖区的相关反洗钱/反恐金融法律的要求。<br>
          </p>
        </div>
        <p class="navigationTitle"> 22.市场动荡、错误和不可抗力<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">22.1不可抗力。<br></span>只要不可抗力事件阻碍了任何义务的履行，双方均不对未能履行义务负责。发生不可抗力事件的一方将：<br>

            (a)迅速通知对方可能导致其无法履行义务的任何情况；<br>
            (b)作出合理的努力，减轻不可抗力事件对其履行义务的影响。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">22.2市场中断。<br></span>如果我们合理地认为，在我们向您提供外汇汇率后发生了市场中断，受市场中断影响的所有或某些货币被市场中断影响，在我们合理地确定市场中断期间已经结束之前，我们可能会修改任何外汇汇率，或任何外汇汇率的任何比率或利差部分，并立即生效。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 22.3外汇汇率的错误。<br></span>如果外汇兑换执行的外汇汇率明显且严重偏离市场价格，贵方不得依赖该明显错误。在这种情况下，我们可能会宣布外汇兑换不具约束力，您需要向我们返还根据外汇兑换进行的任何金额，我们将以正确的汇率重新处理该外汇兑换。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">22.4费用支付。<br></span>本条款不限制或以其他方式影响您根据本协议条款支付任何费用或其他收费或应付款项的义务。<br>
          </p>
        </div>
        <p class="navigationTitle">23.一般条款<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">23.1完整协议。<br></span>本协议条款代表了双方之间的完整协议，并取代和取消了我们之间与之有关的协议、陈述、承诺和声明，无论是书面的还是口头的。每一方承认，在签订本协议条款时，它并不依赖，也不会对本协议条款中没有列出的任何陈述或保证（无论是无意还是疏忽）采取任何补救措施。各方同意，不得因本协议条款中的任何陈述而对无意或疏忽的失实陈述或疏忽的错误声明提出索赔。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.2投诉。<br></span>如果我们犯了错误，或者您觉得我们在提供服务时没有达到您的期望，请让我们知道。我们有内部程序以便根据我们的监管要求公平和及时地处理投诉。我们的投诉程序副本可应要求提供。<br>
          </p>
          <p class="detail">
            <span class="detailTitle"> 23.3通知。<br></span>本条款规定或允许的所有通知、要求和其他通信将以书面形式按双方的注册地址发给双方，并通过以下方式发送：<br>

            (a)挂号信或经认证的一等信；<br>
            (b)快递或隔夜快递服务或亲自送达。<br>
            (c)电子邮件。<br>

            您同意并认可通过电子邮件和其他电子方式（如通过{{ themeData.saasMerNameZh }}平台）接收我们的通知和其他通讯。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.4关系。<br></span>除本条款或任何附加条款中明确规定外，本条款中的任何内容均不得解释为在双方之间建立伙伴关系或合资企业，不得构成任何一方为另一方的代理人，也不得授权任何一方为另一方或代表另一方作出任何承诺。各方确认其是代表自己而非为任何其他人的利益行事。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.5转让。<br></span>未经对方事先书面同意，任何一方不得转让本协议条款的全部或部分内容。任何试图这样做的行为将是无效的，并构成对本协议条款的实质性违反。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.6放弃。<br></span>一方未能或延迟行使本协议条款或法律规定的任何权利或补救措施，或单一或部分行使此类权利或补救措施，均不构成对该权利或任何其他权利或补救措施的放弃，也不排除或限制该权利或任何其他权利或补救措施的进一步行使。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.7可分割性。<br></span>如果本协议条款的任何规定被任何有管辖权的法院确定为无效、不可执行或非法，则该规定将被视为已被删除，而不影响其余规定。如果该条款的某些部分被修改或删除后仍是有 效、可执行和合法的，则该条款将以使其合法、有效和可执行所需的最小修改来适用。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.8不招揽。<br></span>在本协议条款有效期间以及在终止后的一(1)年内，一方("第一方")不得在此前十八(18)个月内向与第一方有任何实质性交易的另一方("第二方")的任何工作人员索取服务。然而，为免生疑问，本条款不适用于：(i) 第一方已事先征得第二方的书面同意；或 (ii) 由第一方或代表第一方进行的任何公开招聘活动，而不是专门针对第二方的任何工作人员。本条款第 24.8 条在本协议条款终止后仍然有效。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.9第三方权利。<br></span>本协议条款是在双方之间专门为双方的利益而订立的，《合同(第三方权利)条例》(第623章)的规定不适用于本条款。任何不是本协议条款的一方的人（无论该人是否在本协议条款中被点名、提及或以其他方式被识别，或是否属于在本协议条款中被点名、提及或被识别的一类人的一部分）均无权根据任何适用法律（包括《合同（第三方权利）条例》）或以其他方式执行本协议条款或享受本协议条款的任何条款的利益。任何一方均不得为了任何第三方的利益而宣布自己是本协议下权利的受托人。<br>

          </p>
          <p class="detail">
            <span class="detailTitle">23.10支付顺序。<br></span>我们可在符合法律规定的前提下，决定我们处理您账户的交易顺序。例如，如果您向我们提供了一个或多个付款指令，但没有向备付金银行账户支付足够的资金，除非我们接受您的其他指示，否则我们可以自主决定使用我们随后收集或收到的任何资金用于一个或多个付款指令的目的。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.11记录。<br></span>您同意，除明显错误的情况外，我们对您使用服务和通过{{ themeData.saasMerNameZh }}平台进行的交易的记录是其内容的最终证据。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.12本产品协议原件一式两份，每方执一份，每份具有同等法律效力。<br></span>

          </p>
          <p class="detail">
            <span class="detailTitle">23.13适用法律。<br></span>本协议条款将受香港法律管辖，并根据香港法律构建。因本合同引起的或与本合同有关的任何争议、纠纷、分歧或索赔，包括本合同的存在、有效性、解释、履行、违反或终止，或因本合同引起的或与本合同有关的非合同义务的任何争议，均应提交香港法院诉讼解决。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">23.14副本<br></span> 本协议可以通过纸质或电子形式签署并且可以签署两份副本，每一份合同都被视为原件并
            且是相同合同。如果本合同以在线形式签署，点击"我确认"或类似按钮，注册、访问或使用服务（统称为"表示同意"），即表示您与{{ themeData.saasMerNameZh }}达成了具有法律约束力的协议并且您同意接受本协议的条款和规则。在您作出表示同意的情形，您和{{ themeData.saasMerNameZh }}同意签署本协议的纸质版，则本协议的纸质版也是有效的，并可追溯至您首次作出指示同意之日起生效。如果本协议的在线版本和纸质形式之间存在不一致的情况，则以纸质版本为准。<br>
          </p>
        </div>
        <p class="navigationTitle">24.定义和解释<br></p>
        <div style="margin-left: 20px">

          <p class="detail">
            <span class="detailTitle">24.1在这些术语中，适用以下定义： 诉讼是指法院司法程序。<br></span>
            附加条款是指适用于使用我们的服务的任何附加使用条款，可在我们的网站{{ themeData.website }}或通过其他{{ themeData.saasMerNameZh }}指定的形式获取，包括适用于在特定地区使用我们的服务的任何特定条款。<br>

            API文件是指可在我们的网站{{ themeData.website }}或通过其他{{ themeData.saasMerNameZh }}指定的形式获取的，适用于{{ themeData.saasMerNameZh }} API的文件和指南。<br>

            适用法律是指所有适用于任何一方本协议条款项下所进行的或实现的活动的法律、法规、规章、具有约束力的行为准则或任何相关监管机构的规则或要求，包括与以下方面有关的法律、法规、规 章、具有约束力的行为准则或任何相关监管机构的规则或要求：<br>
            <span class="use-two-title">(i)货币服务业务；<br></span>
            <span class="use-two-title">(ii)支付服务；<br></span>
            <span class="use-two-title">(iii)反洗钱、了解你的客户、打击恐怖主义融资和制裁要求；<br></span>
            <span class="use-two-title">(iv)数据保护；<br></span>
            <span class="use-two-title">(v)消费者保护；<br></span>
            <span class="use-two-title">(vi)任何监管机构发布的、香港知名金融机构须遵守或惯常遵守的任何实务守则或指引。<br></span>

            授权用户是指您为第7.1条规定的目的而指定的任何人员（例如，您的任何董事、高级职员、雇员 或专业顾问）。<br>

            订单确认是指{{ themeData.saasMerNameZh }}对通过{{ themeData.saasMerNameZh }}平台提出的外币兑换客户请求的回应和确认。<br>

            买入货币指客户通过{{ themeData.saasMerNameZh }}的入口从{{ themeData.saasMerNameZh }}购买的一种支持货币，作为客户请求的一部分。<br>

            工作日是指香港银行通常开门工作日（不包括星期六、星期日或香港的公众假期，或香港悬挂八号或以上台风信号或黑色暴雨预警的任何一天）及/或在账户司法管辖区，账户司法管辖区的支付基础设施在该日开放并且银行进行日常业务活动的日历日（星期六、星期日及公众假期除外）。<br>

            收款服务指{{ themeData.saasMerNameZh }}根据本协议条款向您提供的与{{ themeData.saasMerNameZh }}以支持货币收款有关的收款代理服务。<br>

            保密信息是指并包括所有口头或书面的保密信息：<br>
            <span class="use-two-title">(i)一方从另一方获得或收到的关于一方业务和事务的信息；<br></span>
            <span class="use-two-title">(ii)因履行本协议条款规定的任何义务而产生的信息。<br></span>
            保密信息不包括以下信息：<br>
            (a)接收方在收到披露方的信息之前已经知道，且在披露方面不受限制；<br>
            (b)从合法获得信息的第三方处收到，且该第三方没有义务限制其披露；<br>
            (c)披露方以书面形式批准解除本条款的约束；<br>
            (d)可在公共领域获得且不违反本协议条款的信息；<br>
            (e)在没有获<br>
            得披露方披露的任何保密信息的情况下独立开发的信息。<br>

            确认是指{{ themeData.saasMerNameZh }}对您向我们发出的外汇兑换、支付或两者的指令的回应和确认。<br>

            兑换日是指在外汇兑换中买入的资金以清算资金提供给客户的日期。双方选择进行交易的任何货币对的兑换日必须是两国的工作日（即双方进行交易的国家）。<br>

            备付金银行账户是指以{{ themeData.saasMerNameZh }}名义开设的银行账户，您可以将资金汇入该账户用以任何外汇兑换或支付交易。<br>

            客户数据是指描述您的业务及其运营、您的产品或服务以及您的客户所下订单的信息。<br>

            客户保证金是指适用于您的于{{ themeData.saasMerNameZh }}平台指定的金额，或我们不时以书面形式通知您的任何其他百分比或金额。<br>

            数据指所有客户数据、个人数据、支付数据及{{ themeData.saasMerNameZh }}数据。<br>

            数据主体是指任何可直接或间接通过姓名、识别号码、位置数据等识别符，或通过个人的身体、生理、基因、心理、经济、文化或社会身份等特定因素而被识别的自然人。<br>

            数据保护法规指适用的隐私及数据保护法律，以及任何适用于香港的有关处理个人数据及电子通讯隐私的全国性执行法律、法规及委任立法，并经不时修订、取代或更新。<br>

            直接损失是指，就任何违约而言，违约方知道，或处于违约方地位的一个理性人应该知道的损失：(i)在订立本协议条款时；或(ii)在违约时，可能因违约而合理造成。为避免疑问，即使违约方知道或应该知道有可能发生这种损失或损害，商誉、业务、利润、经营时间、声誉、机会，或数据或信息损坏的损失，或预期节约的损失均不属于直接损失。<br>

            不可抗力事件是指超出一方合理控制范围的事件，包括：罢工、停工、严重的劳资冲突（但不包括该方雇员、代理人或分包商的罢工或其他形式的劳工行动）；公用事业服务(包括互联网、电力、煤气或水)的中断或故障；暴动、战争、大流行病或恐怖袭击；核污染、化学污染或生物污染；极端异常的天气状况；实施制裁、禁运或断绝外交关系；或适用法律的任何变化。<br>

            外汇基准汇率是指我们在考虑了我们的外汇汇率服务提供商提供的成本、银行间汇率和相关市场条件后，为外汇兑换和兑换日指定的货币兑换基准汇率。<br>

            外汇兑换是指双方通过{{ themeData.saasMerNameZh }}平台按约定的汇率以一种货币出售或购买另一种货币的协议。<br>

            外汇兑换服务是指{{ themeData.saasMerNameZh }}根据本协议条款提供的有关外汇兑换的服务。<br>

            外汇汇率是指外汇基本汇率加上可能适用于您的任何其他保证金，包括客户保证金。<br>

            全球账户是指以您的名义注册的唯一分类账账户，作为收款服务的一部分，该账户记录了{{ themeData.saasMerNameZh }}为您向付款人收取的资金数额。<br>

            集团公司是指{{ themeData.saasMerNameZh }}或{{ themeData.saasMerNameZh }}的最终控股公司：(i)直接或间接拥有超过百分之五十(50%)的投票权或已发行股本的任何实体；或(ii)能够控制该实体的活动和业务按照其意愿进行香港是指中华人民共和国香港特别行政区。间接损失是指不属于直接损失的损失。<br>
            破产事件是指：(i)为清算或重组而启动的任何程序；(ii)为就该方或其全部或实质上全部资产委任破产管理人、接管人、行政接管人或受托人而采取的任何步骤或启动的任何程序；(iii)该方全部或绝大部分资产的任何担保的持有人采取任何步骤强制执行该等担保；(iv)该方全部或绝大部分资产被扣押、查封、执行或任何类似程序；(v)该方无力偿还其到期债务；(vi)该方（不论是由该方的董事会或其他人）与其债权人或任何类别的债权人订立协议，包括公司自愿安排协议或安排契据；或(vii)该方（不论是由该方董事会或其他人）根据任何司法管辖区的法律，订立或采取任何步骤，以进行任何类似于上文(i)至(vi)所述程序的程序，而在每一种情况下不包括该方与一家或多家其他公司的非破产合并计划或该方的非破产重组计划。<br>

            知识产权是指：(i)任何专利、注册外观设计、外观设计权、商标、商号（包括与任何商标或商业名称有关的商誉）、版权及相关权利、精神权利、数据库、域名、半导体和其他拓扑权和实用新型，以及包括对这些权利的注册和申请、续展或延长，以及在世界任何地方的类似或同等权利或保护形式的权利，以及与这些权利有关的权利；(ii)不正当竞争权性质的权利，以及对假冒和过去的侵权行为提起诉讼的权利；以及(iii)商业秘密和其他专有权利，包括专有技术和其他技术信息的权利。<br>

            市场中断是指{{ themeData.saasMerNameZh }}有权独自和完全决定的任何行动、事件或情况。(i)直接或间接地阻碍、限制或制约了{{ themeData.saasMerNameZh }}或其集团公司获得确定的报价、将任何货币兑换成任何其他货币、或将任何款项转移到任何其他国家或在同一国家内的能力；(ii)导致银行间外汇市场无法按照正常的商业惯例提供任何购买货币；或(iii)构成外汇市场的异常价格波动。<br>

            商户服务平台或"MSP"是指客户及其授权用户可用于访问{{ themeData.saasMerNameZh }}平台和服务的用户界面。<br>

            网络规则是指由运营{{ themeData.saasMerNameZh }}支持的支付网络的金融服务提供商不时实施的指南、附则、规则、协议和条例（包括Visa、MasterCard或AmericanExpress的卡组织操作规则）。<br>

            {{ themeData.saasMerNameZh }}API是指技术接口，列明客户技术系统与{{ themeData.saasMerNameZh }}平台整合所需的协议和规格，以便授权用户使用服务。<br>

            {{ themeData.saasMerNameZh }}数据是指使用{{ themeData.saasMerNameZh }}基础设施的交易详情、用于欺诈检测和分析的信息、与服务相关的汇总或匿名信息，以及由{{ themeData.saasMerNameZh }}或服务创建或源自{{ themeData.saasMerNameZh }}的任何其他信息。<br>

            {{ themeData.saasMerNameZh }}平台是指由{{ themeData.saasMerNameZh }}设计的专有技术和相关产品（包括但不限于在MSP上和通过 {{ themeData.saasMerNameZh }}API发现的专有技术和相关产品），以向客户提供服务，包括访问全球账户。<br>

            {{ themeData.saasMerNameZh }}账户是指记录您业务细节的电子信息资料，用于登录和使用{{ themeData.saasMerNameZh }}平台。<br>

            付款是指作为支付服务的一部分，{{ themeData.saasMerNameZh }}通过{{ themeData.saasMerNameZh }}平台为您向第三方收款人支付的每一笔款项。<br>

            支付数据是指支付账户信息、与金融服务提供商传递或由金融服务提供商提供的信息、适用法律和网络规则特别规定的金融信息，以及使用我们的服务时产生的任何其他交易信息。<br>

            付款日期是指您通过{{ themeData.saasMerNameZh }}平台向我们发出的指令中指定的日期，以便将付款从{{ themeData.saasMerNameZh }}转给收款人。<br>

            付款服务是指，与{{ themeData.saasMerNameZh }}根据您提供的支付指令以支持货币向收款人做出的任何付款相关，{{ themeData.saasMerNameZh }}根据本协议条款向您提供的支付代理服务。<br>

            个人数据是指通过服务收集、传输或访问的可识别特定自然人（而非公司、法律实体或机器）的信息，数据保护法规另有规定的除外。<br>

            监管机构是指与适用法律的制定、执行、监督或遵守有关的任何个人或监管机构，包括香港海关和任何取代香港海关的监管机构。<br>

            受限人员是指下列人：(i)被列入任何制裁名单，或被列入任何制裁名单的人所拥有或控制；(ii)位于受全国性制裁的国家或地区，或根据该国家或地区的法律成立，或由位于该国家或地区或根据该国家或地区的法律组织的人所拥有或控制，或代表其行事；或(iii)因其他原因而成为制裁对象。<br>

            卖出货币是指客户通过{{ themeData.saasMerNameZh }}平台向{{ themeData.saasMerNameZh }}出售的支持货币之一，作为客户请求的一部分。<br>

            服务指{{ themeData.saasMerNameZh }}根据本协议条款向客户提供的服务，包括通过{{ themeData.saasMerNameZh }}平台提供的收款服务、外汇兑换服务和付款服务。<br>

            服务费是指适用于使用每项服务的所有费用。<br>

            结算截止日是指根据本协议条款以清算资金向{{ themeData.saasMerNameZh }}支付任何款项的时间和日期。一般来说，结算截止日为相关付款的付款日期之前的一个工作日。<br>
            源货币是指客户在客户请求中指定的一种支持货币，用于在{{ themeData.saasMerNameZh }}平台上进行支付。即期交易指的是双方按约定的汇率实时卖出或买入一种货币对另一种货币的交易，但该交易的结算时间应在结算截止日或之前（该时间可根据市场要求而改变，{{ themeData.saasMerNameZh }}应至少提前5个工作日通知客户）。<br>

            支持货币指的是，就服务的每项功能而言，{{ themeData.saasMerNameZh }}不时允许的每一种货币，可以通过{{ themeData.saasMerNameZh }}平台使用该功能收取、兑换和/或支付。<br>

            用户资料指的是，就每个授权用户而言，记录授权用户详细资料的电子信息资料，用于代表您登录和使用{{ themeData.saasMerNameZh }}平台。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">24.2除非上下文另有要求：<br></span>

            (a)单数的使用包括复数，反之亦然。<br>
            (b)凡提及法规或法定条文，均指经不时修订的法律或是行政规章。<br>
            (c).“包括”、“特别是”、“例如”或任何类似词汇所引入的任何短语将被解释为为说明性的，且不会限制该等词汇之前的词汇的意义。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">24.3凡提及各方时，均包括本协议条款允许的任何继承人、获准受让人。<br></span>提到的任何一方对任何人的任何费用、索赔或责任的赔偿包括对任何和所有责任、损失和费用的赔偿，以及（在最大范围内，但仅在适用法律允许的情况下）任何相关监管机构施加的任何民事或刑事罚款以及任何合理产生的法律费用、成本和开支。<br>
          </p>
          <p class="detail">
            <span class="detailTitle">24.4在本协议中，如果上下文允许：<br></span>

            (a)提及某项法定条文时，包括提及：<br>
            <span class="use-two-title">(i)任何当时有效的命令、条例、法定文书或其他附属法例（无论何时制定）；<br></span>
            <span class="use-two-title">(ii)对其进行任何修改、修订、合并、重新制定或替换，或对其规定进行修改、修订、合并、重新制定或替换，但在本协议日期后进行的任何修改、修订、合并、重新制定或替换将增加本协议任何一方的责任的情况除外。<br></span>
            (b)凡提及条款、附表或段落，即分别指本协议的条款、附表或附表的段落或本协议的段落。<br>
            (c)提及本协议各方包括其各自的继承人、允许的受让人和个人代表。<br>
            (d)提及由多于一人组成的本协议任何缔约方时，包括构成该缔约方的每个人。<br>
            (e)任何性别的提法包括另一性别。<br>
            (f)对人的提及包括法人或非法人。<br>
            (g)对任何专业事务所或公司的提及，包括任何有效地继承其全部或实质上全部执业或业务的事务所或公司。<br>
            (h)由多人明示作出或订立的所有保证、陈述、协议和义务，均由有关人员共同和分别作出或订立。<br>
            (i)索引、标题和任何描述性说明仅为便于参考，不影响本协议的解释或诠释。<br>
            (j)就任何文件而言，"商定的格式"是指缔约方之间商定的、由缔约方或代表缔约方草签的、仅用于识别的格式；<br>
            (k)凡提及"商定格式"的文件，均指采用各方商定的格式并由各方草签的文件，以资识别；<br>
            (l)“书面形式”包括传真和电子邮件。<br>
            (m)本协议中对某人规定的不做某事的任何义务包括不同意或不允许做该事的义务。<br>
            (n)在文意允许的情况下，“其他的”和“其他”是说明性的，不应限制前面词语的含义。<br>
            (o)除文意另有所指外，提述“一天”的时间是指香港时间，而提述一天的时间是指由前一天午夜开始的24小时。<br>
          </p>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { reactive, toRefs, inject } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'TermsOfServiceCN',
  setup () {
    const router = useRouter()
    const state = reactive({
      themeData: inject('$themeData')
    })

    const clauseSupplement = () => {
      const clauseSupplyUrl = router.resolve({ name: 'clauseSupply' })
      window.open(clauseSupplyUrl.href, '_blank')
    }

    const usePolicy = () => {
      const usePolicyUrl = router.resolve({ name: 'usePolicy' })
      window.open(usePolicyUrl.href, '_blank')
    }

    return {
      clauseSupplement,
      usePolicy,
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
p.detail{margin-top: 20px;}
p.navigationTitle{margin-top: 20px;font-size: 18px;color: black;font-weight: bold;}
span.detailTitle{font-size: 15px;color: black;font-weight: bold;margin-bottom: 10px}
span.use-two-title {margin-left: 20px;}
</style>

